/*
Author: Eli Elad Elrom
*/

import React, { useState } from 'react';
import Link from 'next/link';
import {
  WrapperOpen,
  CloseButtonWrapper,
  Row,
  DialogSectionTitle,
  DialogSection,
} from './DialogPanel.css';
import CloseButton from '../../shared/CloseButton';
import { MenuButton } from '../../layout/StickyHeader/Header.css';
import { Menu } from '../../shared/Svg';
import { useRouter } from 'next/router';

const DialogPanel = ({ isVisible }: TooltipProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const router = useRouter();

  const handleClick = (_e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, path: string) => {
    setIsOpen(false);
    router.push(path).then((_r) => {});
  };

  return (
    <>
      {isOpen ? (
        <WrapperOpen id="dialog-panel" isVisible={isVisible}>
          <CloseButtonWrapper>
            <CloseButton
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            />
          </CloseButtonWrapper>
          <DialogSection className="mapbox-dialog-section-dots-colors">
            <DialogSectionTitle>CONTENT</DialogSectionTitle>
            <Row>
              <Link href="/courses">
                <a onClick={(e) => handleClick(e, '/courses')}>Courses</a>
              </Link>
            </Row>
            <Row>
              <Link href="/books">
                <a onClick={(e) => handleClick(e, '/books')}>Books</a>
              </Link>
            </Row>
            <Row>
              <Link href="/articles">
                <a onClick={(e) => handleClick(e, '/articles')}>Articles</a>
              </Link>
            </Row>
          </DialogSection>

          <DialogSection className="mapbox-dialog-section-dots-colors">
            <DialogSectionTitle>TELL ME MORE</DialogSectionTitle>
            <Row>
              <Link href="/showcase">
                <a onClick={(e) => handleClick(e, '/showcase')}>Showcase</a>
              </Link>
            </Row>
            <Row>
              <Link href="/about">
                <a onClick={(e) => handleClick(e, '/about')}>About</a>
              </Link>
            </Row>
            <Row>
              <Link href="/coding">
                <a onClick={(e) => handleClick(e, '/coding')}>Coding</a>
              </Link>
            </Row>
            <Row>
              <Link href="/coaching">
                <a onClick={(e) => handleClick(e, '/coaching')}>Coaching</a>
              </Link>
            </Row>
          </DialogSection>
        </WrapperOpen>
      ) : (
        <MenuButton
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <Menu />
        </MenuButton>
      )}
    </>
  );
};

DialogPanel.defaultProps = {
  isVisible: false,
};

export interface TooltipProps {
  isVisible: boolean;
}

export default DialogPanel;
