import styled from 'styled-components';

import { breakpoint } from '../../../styles/breakpoints';

export const HeaderWrapper = styled.div<{ isLightsOn: boolean }>`
  font-family: 'RevxNeue', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  background: ${(props) =>
    props.isLightsOn ? props.theme.colors.easyGreenBackground : props.theme.colors.lightGrey};
  display: flex;
  width: 100%;
  height: 60px;
  flex-direction: row;
  padding-left: 25px;
  align-items: center;
  ${breakpoint('mobile')`
    padding-left: 20px;
    background: ${(props) => props.theme.colors.white};
  `}
`;

export const SubText = styled.p`
  ${(props) => props.theme.typography.typeStyleSansSerifMaintenancePageText}
  color: ${({ theme }) => theme.colors.txtDarkGrey};
  width: 888px;
  height: 72px;
  text-align: center;
  padding: 50px;
  ${breakpoint('mobile')`
    width: 311px;
    height: 265px;
    padding: 50px 12px 0px 12px;
  `}
`;
