/*
Author: Eli Elad Elrom
*/

import styled from 'styled-components';
import { breakpoint } from '../../../styles/breakpoints';

export const WrapperOpen = styled.div<{ isVisible: boolean }>`
  background-color: ${(props) => props.theme.colors.grey};
  border: 1px solid ${(props) => props.theme.colors.cloudGrey};
  ${(props) => props.theme.typography.typeStyleOpenSansScatterChartText}
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  border-radius: 0.4em;
  position: absolute;
  width: 20em;
  height: 47em;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  top: 1em;
  right: 5em;
  padding: 0.6em;
  text-align: left;
  opacity: 1;
  z-index: 999;
  transition: all 0.2s ease-out;

  ${breakpoint('mobile')`
    height: 33em;
  `}
`;

export const ImageButton = styled.div`
  padding-top: 0.2em;
  cursor: pointer;
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  right: 0.2em;
  top: 0.1em;
`;

export const Row = styled.div`
  ${(props) => props.theme.typography.typeStyleRevxNeueText1}
  display: flex;
  align-items: center;
  color: white;
  padding-left: 5px;
`;

export const Column = styled.div`
  width: 50%;
`;

export const DialogSectionTitle = styled.span`
  ${(props) => props.theme.typography.typeStyleRevxNeueText1}
  color: ${(props) => props.theme.colors.midnightMain};
  font-weight: bold;
  color: white;
  padding-left: 5px;
`;

export const DialogSection = styled.div`
  color: ${(props) => props.theme.colors.midnightLight};
  margin-bottom: 0.1em;
  font-weight: 400;
  padding-top: 0.5em;
`;
