import styled from 'styled-components';

import { breakpoint } from '../../../styles/breakpoints';
import NavLink from '../NavLink';

export const HeaderRoot = styled.header`
  position: fixed;
  display: flex;
  height: 6em;
  width: 100%;
  z-index: 100;
  padding-top: 1.6em;
  padding-right: 6em;

  ${breakpoint('mobile')`
      height: 45px;
  `};
`;

export const MainHeader = styled.div`
  width: 100%;
  padding-top: 1.5em;
`;

export const LogoOneDiv = styled.div`
  position: absolute;
`;

export const LogoOne = styled.span`
  ${(props) => props.theme.typography.typeStyleRevxNeueLogoLetter};
  background: linear-gradient(#000000, #454545);
  color: ${(props) => props.theme.colors.white};
  position: absolute;
  top: -0.53em;
  left: -0.15em;
  padding-top: 0.16em;
  transform: scale(1, 1);
`;

export const LogoTwo = styled.span<{ isVisible?: boolean }>`
  ${(props) => props.theme.typography.typeStyleRevxNeueLogo}
  position: absolute;
  padding-left: inherit;
  padding-right: inherit;
  left: 0.52em;
  display: none;
  display: ${(props) => `${props.isVisible ? 'inline' : 'hidden'}`};
`;

export const Nav = styled.nav`
  display: flex;
  justify-content: flex-end;
`;

export const NavList = styled.ul`
  white-space: nowrap;
  padding: 0 41px 0 0;

  ${breakpoint('mobile')`
    display: none;
  `}
`;

export const NavItem = styled.li`
  ${(props) => props.theme.typography.typeStyleBodyExtraBold}
  margin-left: 55px;
  display: inline-block;

  ${breakpoint('mobile')`
    margin-left: 0;
  `}
`;

export const StyledLink = styled(NavLink)`
  ${(props) => props.theme.typography.typeStyleBodyExtraBold}
  align-items: center;
  color: ${(props) => props.theme.colors.black};
  display: inline-flex;
  text-decoration: none;

  :hover {
  }

  :focus {
  }
`;

export const NavigationItem = styled.div`
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }

  ${StyledLink} {
    color: ${(props) => props.theme.colors.black};
    transition: transform 200ms ease-in-out;

    &:focus,
    &:hover {
      transform: scale(1.125);
    }
  }
`;

export const BulbWrapper = styled.span`
  margin-right: 10px;
  height: 5px;
  width: 5px;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
  }
  visibility: hidden;
`;

export const LinkText = styled.span``;

export const LogoutText = styled(LinkText)`
  color: ${(props) => props.theme.colors.icBlue};

  &:hover {
    color: ${(props) => props.theme.colors.icBlueGrey};
  }
`;

export const MenuButton = styled.button`
  background-color: ${(props) => props.theme.colors.grey};
  border: none;
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  display: none;
  padding: 8px 8px;
  display: block;

  svg {
  }
`;

export const Navigation = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  ${breakpoint('mobile')`
    display: none;
  `}
`;
