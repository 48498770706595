import { css } from 'styled-components';

export enum BreakpointsEnum {
  DESKTOP_MIN_WIDTH = 960.99,
  MOBILE_MAX_WIDTH = 960,
  TABLET_MAX_WIDTH = 1450,
}

export const breakpoints = {
  desktopOnly: 'screen and (min-width: 960.99px)',
  mobile: 'screen and (max-width: 960px)',
  tablet: 'screen and (max-width: 1450px)',
};

export const breakpoint =
  (key: keyof typeof breakpoints) =>
  (...args: Parameters<typeof css>) => {
    const styles = css(...args);
    return (props: { theme: { breakpoints: typeof breakpoints } }) =>
      css`
        @media ${props.theme.breakpoints[key]} {
          ${styles}
        }
      `;
  };

export default breakpoints;
