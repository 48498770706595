const React = require('react');

const CircledArrow = (props: { strokeColor: string }) => (
  <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.42 13.6401L16 18.6001L21.58 13.6401"
      stroke={props.strokeColor}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <circle cx="16" cy="15.5" r="14.5" stroke={props.strokeColor} strokeWidth="2" />
  </svg>
);

export default CircledArrow;
