import { css } from 'styled-components';

const GeoSlab703BT = css`
  @font-face {
    font-family: 'GeoSlab703BT';
    src: url('/fonts/GeoSlab703BT/GeometricSlab703BT-Medium.ttf') format('truetype');
  }
`;

export default GeoSlab703BT;
