import * as React from 'react';

const SvgBulb = () => (
  <svg width="89" height="107" viewBox="0 0 89 107" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.8261 44.2702C24.1516 58.001 42.3315 75.6351 42.3315 75.6351V100.83L48.0053 107H61.9317L67.6054 100.83V75.6351C67.6054 75.6351 85.35 57.8981 83.5951 44.2702C81.58 28.6221 69.495 16.4561 53.679 17.0188C38.4477 17.5606 27.6655 29.188 25.8261 44.2702Z"
      fill="#D9F2FB"
    />
    <path
      d="M15.7174 40.5002C14.0941 53.8523 31.7174 71.0002 31.7174 71.0002V95.5002L37.2174 101.5H50.7174L56.2174 95.5002V71.0002C56.2174 71.0002 73.4185 53.7523 71.7174 40.5002C69.764 25.2836 58.049 13.4531 42.7174 14.0002C27.9525 14.527 17.5004 25.8339 15.7174 40.5002Z"
      stroke="black"
      strokeWidth="2"
    />
    <path
      d="M40.2212 73.6423V55.6423C40.2212 55.6423 41.4642 51.8939 40.2212 50.1423C39.2104 48.718 37.9665 48.0799 36.2212 48.1423C33.7375 48.231 31.8693 50.1614 31.7212 52.6423C31.6013 54.649 34.2212 57.1423 34.2212 57.1423H52.2212C52.2212 57.1423 56.2211 55.1277 56.2211 52.6424C56.2211 50.1571 54.6609 48.6157 52.2212 48.1423C49.9775 47.7069 47.9913 48.2422 46.7212 50.1423C45.5274 51.9279 46.7212 55.6423 46.7212 55.6423V73.6423"
      stroke="black"
      strokeWidth="1.5"
    />
    <line x1="31.7172" y1="74.25" x2="56.7172" y2="74.25" stroke="black" strokeWidth="1.5" />
    <line x1="31.7172" y1="88.25" x2="56.7172" y2="88.25" stroke="black" strokeWidth="1.5" />
    <line x1="31.7172" y1="81.25" x2="55.7172" y2="81.25" stroke="black" strokeWidth="1.5" />
    <line x1="31.7172" y1="95.25" x2="55.7172" y2="95.25" stroke="black" strokeWidth="1.5" />
    <line
      x1="12.3392"
      y1="26.318"
      x2="6.03519"
      y2="22.378"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="8.43399"
      y1="44.5"
      x2="1.00001"
      y2="44.5"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="11.346"
      y1="62.3825"
      x2="5.10005"
      y2="66.4138"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="25.8378"
      y1="13.4264"
      x2="21.4904"
      y2="7.39613"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="43.7172"
      y1="8.43408"
      x2="43.7172"
      y2="1.0001"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="62.3511"
      y1="12.8039"
      x2="66.0681"
      y2="6.36588"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="75.0832"
      y1="26.3508"
      x2="81.5212"
      y2="22.6338"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="79.7172"
      y1="44"
      x2="87.1511"
      y2="44"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="76.0832"
      y1="62.634"
      x2="82.5212"
      y2="66.351"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default SvgBulb;
