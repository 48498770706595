import * as React from 'react';
import { SVGProps } from 'react';

const SvgSwoosh = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.882 10.056C9.324 7.88 7.722 5.902 6.068 4.207 4.544 2.638 2.982 1.32 1.411.084.951-.28.57.6.351 1.546a7.786 7.786 0 0 0-.215 1.82c.004.364.058 1.067.23 1.205C1.916 5.79 3.453 7.088 4.954 8.62c.268.276.536.559.8.848 1.119 1.198 1.578 3.018.636 4.876-.191.176-.383.351-.578.52-1.597 1.42-3.236 2.499-4.887 3.446-.479.276-.823 1.676-.908 2.787-.072.941.077 2.14.556 1.864 1.65-.954 3.29-2.04 4.886-3.446 1.662-1.468 3.286-3.22 4.914-4.92.375-.396.643-1.657.716-2.592.008-.126.015-.264.015-.408.039-.621-.003-1.23-.222-1.537Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSwoosh;
