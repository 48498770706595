import styled, { css } from 'styled-components';
import { breakpoint } from '../../../styles/breakpoints';

export interface Props {
  responsiveDisabled?: boolean;
  variant: 'primary' | 'secondary' | 'tertiary';
}

const VARIANT_STYLES = {
  primary: {
    backgroundColor: 'darkGreen',
    borderColor: 'transparent',
    color: 'white',
  },
  secondary: {
    backgroundColor: 'brightGreen',
    borderColor: 'softGreen',
    color: 'darkPurple',
  },
  tertiary: {
    backgroundColor: 'white',
    borderColor: 'darkPurple',
    color: 'darkGreen',
  },
} as const;

export const styles = ({ variant = 'primary', responsiveDisabled }: Props) => css`
  ${(props) => props.theme.typography.typeStyleButtonSmall}
  background-color: ${(props) => props.theme.colors[VARIANT_STYLES[variant].backgroundColor]};
  border-color: ${(props) => props.theme.colors[VARIANT_STYLES[variant].borderColor]};
  border-style: solid;
  border-width: 1px;
  color: ${(props) => props.theme.colors[VARIANT_STYLES[variant].color]};
  cursor: pointer;
  min-width: 100px;
  padding: 5px 5px;
  text-align: center;
  text-decoration: none;
  transition: all 200ms;

  ${(props) =>
    responsiveDisabled
      ? ''
      : css`
          ${breakpoint('mobile')`
            ${props.theme.typography.typeStyleButtonSmall}
            min-width: 172px;
          `}
        `}

  &:hover {
    background-color: ${(props) => props.theme.colors.blue};
    color: ${(props) => props.theme.colors.white};
  }

  &:disabled {
    background-color: ${(props) => props.theme.colors.icBlueGrey};
    border-color: ${(props) => props.theme.colors.transparent};
    color: ${(props) => props.theme.colors.black};
    cursor: default;
  }
`;

const Button = styled.button<Props>`
  ${styles}
`;

export default Button;
