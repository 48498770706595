import { css } from 'styled-components';

const OpenSans = css`
  @font-face {
    font-family: 'Open Sans';
    font-weight: 700;
    src: url('/fonts/OpenSans/OpenSans-Bold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Open Sans';
    font-weight: 700;
    font-style: italic;
    src: url('/fonts/OpenSans/OpenSans-BoldItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Open Sans';
    font-weight: 800;
    src: url('/fonts/OpenSans/OpenSans-ExtraBold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Open Sans';
    font-weight: 800;
    font-style: italic;
    src: url('/fonts/OpenSans/OpenSans-ExtraBoldItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Open Sans';
    font-weight: 200;
    font-style: italic;
    src: url('/fonts/OpenSans/OpenSans-ExtraLightItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Open Sans';
    font-weight: 400;
    font-style: italic;
    src: url('/fonts/OpenSans/OpenSans-Italic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Open Sans';
    font-weight: 300;
    src: url('/fonts/OpenSans/OpenSans-Light.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Open Sans';
    font-weight: 300;
    font-style: italic;
    src: url('/fonts/OpenSans/OpenSans-LightItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Open Sans';
    font-weight: 400;
    src: url('/fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Open Sans';
    font-weight: 600;
    src: url('/fonts/OpenSans/OpenSans-SemiBold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Open Sans';
    font-weight: 600;
    font-style: italic;
    src: url('/fonts/OpenSans/OpenSans-SemiBoldItalic.ttf') format('truetype');
  }
`;

export default OpenSans;
