import styled, { css } from 'styled-components';
import { styles as buttonStyles } from '../../shared/Button';
import { Variant } from './types';

interface Props {
  variant: Variant;
  responsiveDisabled?: boolean;
}

export const styles = ({ variant, responsiveDisabled }: Props) => css`
  ${(props) =>
    (variant === 'large' || variant === 'largeHover') && props.theme.typography.typeStyleLinkLarge}
  ${(props) =>
    (variant === 'small' || variant === 'smallHover') && props.theme.typography.typeStyleLinkSmall}
  color: ${(props) =>
    variant === 'largeHover' || variant === 'smallHover'
      ? props.theme.colors.txtBlue
      : props.theme.colors.icDarkBlue};
  text-decoration: ${() =>
    variant === 'largeHover' || variant === 'smallHover' ? 'none' : 'underline'};

  &:hover {
    color: ${(props) => props.theme.colors.txtBlue};
    text-decoration: none;
  }

  ${() =>
    (variant === 'primary' || variant === 'secondary' || variant === 'tertiary') &&
    buttonStyles({ variant, responsiveDisabled })}
`;

export const StyledLink = styled.a<Props>`
  ${styles}
  transform: scale(1);
`;
