import { css } from 'styled-components';

const GeoSlab703 = css`
  @font-face {
    font-family: 'GeoSlab703';
    font-weight: 200;
    src: url('/fonts/GeoSlab703/tt0310m_.ttf') format('truetype');
  }

  @font-face {
    font-family: 'GeoSlab703';
    font-weight: 300;
    src: url('/fonts/GeoSlab703/tt0310m_.ttf') format('truetype');
  }

  @font-face {
    font-family: 'GeoSlab703';
    font-weight: 400;
    src: url('/fonts/GeoSlab703/tt0311m_.ttf') format('truetype');
  }

  @font-face {
    font-family: 'GeoSlab703';
    font-weight: 500;
    src: url('/fonts/GeoSlab703/tt0311m_.ttf') format('truetype');
  }
`;

export default GeoSlab703;
