import { css } from 'styled-components';

const RevxNeue = css`
  @font-face {
    font-family: 'RevxNeue';
    font-weight: 200;
    src: url('/fonts/RevxNeue/RevxNeue-Regular.ttf');
  }

  @font-face {
    font-family: 'RevxNeue';
    font-weight: 200;
    font-style: italic;
    src: url('/fonts/RevxNeue/RevxNeue-Italic.ttf');
  }

  @font-face {
    font-family: 'RevxNeue';
    font-weight: 300;
    src: url('/fonts/RevxNeue/RevxNeue-Regular.ttf');
  }

  @font-face {
    font-family: 'RevxNeue';
    font-weight: 300;
    font-style: italic;
    src: url('/fonts/RevxNeue/RevxNeue-Italic.ttf');
  }

  @font-face {
    font-family: 'RevxNeue';
    font-weight: 400;
    src: url('/fonts/RevxNeue/RevxNeue-Regular.ttf');
  }

  @font-face {
    font-family: 'RevxNeue';
    font-weight: 400;
    font-style: italic;
    src: url('/fonts/RevxNeue/RevxNeue-Italic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'RevxNeue';
    font-weight: 600;
    src: url('/fonts/RevxNeue/RevxNeue-SemiBold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'RevxNeue';
    font-weight: 600;
    font-style: italic;
    src: url('/fonts/RevxNeue/RevxNeue-SemiBoldItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'RevxNeue';
    font-weight: 700;
    src: url('/fonts/RevxNeue/RevxNeue-SemiBold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'RevxNeue';
    font-weight: 700;
    font-style: italic;
    src: url('/fonts/RevxNeue/RevxNeue-SemiBoldItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'RevxNeue';
    font-weight: 800;
    src: url('/fonts/RevxNeue/RevxNeue-SemiBold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'RevxNeue';
    font-weight: 800;
    font-style: italic;
    src: url('/fonts/RevxNeue/RevxNeue-SemiBoldItalic.ttf') format('truetype');
  }
`;

export default RevxNeue;
