import React, { ButtonHTMLAttributes } from 'react';
import { Button } from './CloseButton.css';
import Icon from '../Icon';

interface CloseButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
}
const CloseButton = ({ ...rest }: CloseButtonProps) => (
  <Button type="button" {...rest} id="modalClose">
    <Icon iconFilename="close-modal-grey.svg" height={14} width={14} alt="Close" />
  </Button>
);

CloseButton.defaultProps = {
  className: '',
};

export default CloseButton;
