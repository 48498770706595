import React from 'react';
import Link from 'next/link';
import { StyledLink } from './NavLink.css';
import { Props } from './types';

const NavLink = ({
  responsiveDisabled,
  variant,
  href,
  as,
  replace,
  scroll,
  shallow,
  passHref,
  prefetch,
  locale,
  children,
  ...rest
}: Props) => (
  <Link
    href={href}
    as={as}
    passHref
    replace={replace}
    scroll={scroll}
    shallow={shallow}
    prefetch={prefetch}
    locale={locale}
  >
    <StyledLink responsiveDisabled={responsiveDisabled} variant={variant} {...rest}>
      {children}
    </StyledLink>
  </Link>
);

export default NavLink;
